import { Button } from 'components/Button'
import { Text } from 'components/Text'
import { Title } from 'components/Title'
import { LoginState } from 'contexts/LoginProvider'
import { useLoginContext } from 'hooks/useLoginContext'

export function MagicLink() {
  const { values, status } = useLoginContext()

  return (
    <>
      <div className="flex flex-col items-center justify-center">
        <Title center type="h1" weight="bold" size="2xl" className="mb-6" ariaLabel="Check your email">
          Check your email
        </Title>
      </div>
      {status === LoginState.MagicLink ? (
        <div className="mb-8 mt-4 text-center">
          <Text variant="light">
            {'We sent an email to you at '}
            <span className="font-bold text-gray-800 dark:text-gray-100">{values.email}</span>
            {'. It contains a link that will allow you to sign in.'}
          </Text>
        </div>
      ) : (
        <p className="mb-8 rounded-md border border-red-200 bg-red-50 p-3 text-sm text-red-600 dark:border-red-400 dark:bg-red-200 dark:text-red-800">
          <span className="mb-2 block font-medium">Invalid login link</span>
          <span>The link you tried accessing is either invalid or has expired.</span>
        </p>
      )}

      <Button type="submit" disabled={values.email.length < 1} block>
        Enter code manually
      </Button>
    </>
  )
}
